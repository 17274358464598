<template>
  <div
    id="app"
    :class="classTheme"
    v-if="libraryLoaded == true">
    <div v-if="!authenticationStore.isEnabledCcpGetter && newIncomingContact && resetSMSInQueue">
      <IncomingModal
        :tag="newIncomingContact.type"
        :mdl-data="newIncomingContact"
        :show="showModal"
        @decline="onDismissIncomingCall($event)"
        @accept="answerContact(newIncomingContact)"
        @stop-ringer="stopRinger()"
        @missed="handleMissedChat($event)"
        :is-accepting="$wait.is('connectingCallToAgent')" />
    </div>
    <Loading v-if="uiStore.isLoadingVisible" />
    <transition
      name="zoom"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { isEmpty, get, clone } from "lodash";
import IncomingModal from "@/components/incoming-modal/IncomingModal";
import alert_tones from "@/assets/tones";
import alert_tones_secondary from "@/assets/tones-b";
import alert_tones_secondary_main from "@/assets/tones-c";
import { PeerCallStatus } from "@/common/constant";
import Socket from "@/common/socket";
import AmplifyHandler from "./plugins/amplify";
import ActivityService from "@/common/services/activityService-shim";
import Amplify from "@aws-amplify/core";
import Loading from "@/components/UI/Loading";
import { oneClickSetup, ContactFilter } from "@/common/constant.js";
import { DARK, LIGHT, CHANNELS } from "@connectpath/common-frontend/src";
import { INITIATION_METHODS } from "@connectpath/common";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { useActivityStore } from "@/piniaStore/activity";
import { useAuthenticationStore } from "@/piniaStore/authentication";
import { useChannelStore } from "@/piniaStore/channel";
import { useChatStore } from "@/piniaStore/chat";
import { useContactStore } from "@/piniaStore/contact";
import { useContactsStore } from "@/piniaStore/contacts";
import { useUiStore } from "@/piniaStore/ui";
import { usePermissionsStore } from "@/piniaStore/permissions";
import { useHidStore } from "@/piniaStore/hid";
import { useHolidayStore } from "@/piniaStore/holiday";
import { useIframeIntegrationsStore } from "@/piniaStore/iframeIntegrations";
import { useLogsStore } from "@/piniaStore/logs";
import { useMediaDevicesStore } from "@/piniaStore/mediaDevices";
import { useSettingsStore } from "@/piniaStore/settings";
import { usePhoneStore } from "@/piniaStore/phone";
import { useCurrentUserStore } from "@/piniaStore/current-user";
import { useApiStore } from "./piniaStore/api";
import { useIndexStore } from "./piniaStore/index";
import { useAgentsStore } from "./piniaStore/agents";
import { useQueueStore } from "@/piniaStore/queue";
import { useTeamStore } from "./piniaStore/team";
const amplify = new AmplifyHandler();

let ding = null;
let dingSecondary = null;

export default {
  setup() {
    const activityStore = useActivityStore();
    const authenticationStore = useAuthenticationStore();
    const channelStore = useChannelStore();
    const chatStore = useChatStore();
    const contactStore = useContactStore();
    const contactsStore = useContactsStore();
    const uiStore = useUiStore();
    const permissionStore = usePermissionsStore();
    const hidStore = useHidStore();
    const holidayStore = useHolidayStore();
    const iframeIntegrationStore = useIframeIntegrationsStore();
    const logStore = useLogsStore();
    const mediaDeviceStore = useMediaDevicesStore();
    const settingStore = useSettingsStore();
    const phoneStore = usePhoneStore();
    const currentUserStore = useCurrentUserStore();
    const apiStore = useApiStore();
    const indexStore = useIndexStore();
    const agentStore = useAgentsStore();
    const queueStore = useQueueStore();
    const teamStore = useTeamStore();
    return {
      activityStore,
      authenticationStore,
      channelStore,
      chatStore,
      contactStore,
      contactsStore,
      uiStore,
      permissionStore,
      hidStore,
      holidayStore,
      iframeIntegrationStore,
      logStore,
      mediaDeviceStore,
      settingStore,
      phoneStore,
      currentUserStore,
      apiStore,
      indexStore,
      agentStore,
      queueStore,
      teamStore,
    };
  },
  name: "App",
  data() {
    return {
      libraryLoaded: false,
      initialTimeout: null,
      tones: alert_tones,
      tonesSecondaryMain: alert_tones_secondary_main,
      tonesSecondary: alert_tones_secondary,
      showIncomingModal: false,
      dingPrimaryEmail: null,
      dingSecondaryEmail: null,
      silence: require("./assets/Ringing_Phone.mp3"),
      hasPlayed: false,
      secTimeout: null,
      devices: {
        audio: [],
        video: [],
      },
    };
  },
  components: {
    IncomingModal,
    Loading,
  },
  computed: {
    classTheme() {
      return this.settingStore.isDarkMode === true ? DARK.CLASS_THEME : LIGHT.CLASS_THEME;
    },
    inboundAttributesActiveVoiceContact() {
      return this.contactStore.activeVoiceContact;
    },
    outboundAttributesActiveVoiceContact() {
      return this.contactStore.outboundAttributes;
    },
    mobileView() {
      return this.indexStore.mobileView;
    },

    callStatus() {
      return this.teamStore?.callStatus;
    },

    newSMSContactReceived() {
      return this.phoneStore.newSMSContactReceived;
    },

    showSMSContact() {
      return this.phoneStore.showSMSContact;
    },

    winWidth() {
      return this.indexStore.getWinWidth;
    },

    currentUser() {
      return this.apiStore.currentUser;
    },

    agentSummary() {
      return this.agentStore.agentSummary;
    },

    agentStatus() {
      return this.agentStore?.agentStatus;
    },

    api() {
      return this.apiStore?.getApi;
    },

    newIncomingContact() {
      let contact = this.contactStore.newIncomingContact;
      return contact;
    },

    resetSMSInQueue() {
      let contact = this.contactStore.newIncomingContact;
      if (contact.type === ContactFilter.SMS) {
        if (contact?.summary?.From === this.phoneStore.getSMSContactInQueue?.phoneNumber) {
          this.phoneStore.setSMSContactInQueue({
            phoneNumber: "",
            timeStamp: "",
          });
        }
      }
      return true;
    },

    getChatRejectedContacts() {
      return this.contactStore.chatRejectedContacts;
    },

    isAcceptingNewSMSContact() {
      return this.phoneStore.acceptingSMSContact;
    },

    mediaDevices() {
      return this.mediaDeviceStore?.audioOutputDevices;
    },
    alerttone() {
      return !this.api.getUser.Preferences.PrimaryRinger.Sound ? "default" : this.api.getUser.Preferences.PrimaryRinger.Sound;
    },
    alerttoneSecondary() {
      return !this.api.getUser.Preferences.SecondaryRinger.Sound
        ? "default"
        : this.api.getUser.Preferences.SecondaryRinger.Sound;
    },
    isEnabledSecondary() {
      return this.api.getUser.Preferences.SecondaryRinger.isEnabled;
      // this.deviceId !== this.deviceIdSecondary
    },
    deviceId() {
      let deviceId = get(this.api, "getUser.Preferences.PrimaryRinger.DeviceId");
      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        return find.deviceId;
      } else {
        return "default";
      }
    },
    deviceIdSecondary() {
      let deviceId = get(this.api, "getUser.Preferences.SecondaryRinger.DeviceId");
      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        return find.deviceId;
      } else {
        return "default";
      }
    },
    autoAcceptCall() {
      return this.currentUser?.PhoneConfig?.AutoAccept;
    },
    primaryToneVolume() {
      return !this.api.getUser.Preferences.PrimaryRinger.Volume
        ? 50 / 100
        : this.api.getUser.Preferences.PrimaryRinger.Volume / 100;
    },
    secondaryToneVolume() {
      return !this.api.getUser.Preferences.SecondaryRinger.Volume
        ? 50 / 100
        : this.api.getUser.Preferences.SecondaryRinger.Volume / 100;
    },
    language() {
      return this.settingStore?.language || "en";
    },
    softPhone() {
      return this.currentUserStore?.securityProfile?.SoftPhone;
    },
    showModal() {
      return this.newIncomingContact && !this.contactStore.getMonitorMode;
    },
  },
  beforeCreate() {
    if (this.$wait) {
      this.$wait.start("initialAppLoad");
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyListener);
    this.sockets.unsubscribe(`${this.agentSummary.Username}-missedVoiceCall`);

    // CHECKING IF THE USER SUBSCRIPTION UPDATE INTERVAL IS RUNNING AND DESTROYING IT.
    if (window.intervalUpdateUser) {
      clearInterval(window.intervalUpdateUser);
      window.intervalUpdateUser = null;
    }
  },

  mounted() {
    this.uiStore.initUI();
    window.Broadcast.$on("answerContact", () => {
      this.answerContact(this.newIncomingContact);
    });

    window.Broadcast.$on("rejectContact", () => {
      this.onDismissIncomingCall("REJECTED");
    });
    window.isLoggingOut = false;
    document.addEventListener("keydown", this.keyListener.bind(this));

    // send all errors to the error logger
    window.onerror = (msg, source, lineNo, columnNo, error) => {
      let payload = {
        component: "DEXTR",
        level: "ERROR",
        text: error,
      };

      if (payload) {
        this.logStore.addItem(payload);
      }
    };

    // check if this page was routed to because of a forced mult-session logout

    window.local_track = {
      enabled: false,
    };

    if (this.winWidth <= 1024) {
      this.uiStore.setSidebarShown(false);
    }

    // Checking If not chrome
    let browserArray = ["Trident", "Edge", "MSIE", "CriOS", "Firefox", "OPR", "Chrome"];
    let browser;
    let userBrowser = window.navigator.userAgent; //browser string

    for (let i = 0; i < browserArray.length; i++) {
      if (userBrowser.includes(browserArray[i])) {
        browser = browserArray[i];
        break;
      }
    }

    this.checkMobileView();

    const isChrome = browser === "Chrome";
    this.initialTimeout = setTimeout(async () => {
      if (!isChrome) {
        this.$Modal.warning({
          title: this.$t("modal.browserCompatibilityIssue"),
          render: (h) => {
            return h("span", [
              `${this.$t("modal.resultsMayVaryOnThisBrowserForBestResultsSeeSystemRequirementsOn")} `,
              h(
                "a",
                { attrs: { href: `${process.env.VUE_APP_DEXTR_DOCUMENTATION_URL}/system-requirements/`, target: "_blank" } },
                `${process.env.VUE_APP_DEXTR_DOCUMENTATION_URL}/system-requirements/`
              ),
            ]);
          },
        });
      }
      const { name } = this.$route;

      const oldStore = JSON.parse(localStorage.getItem("vuex"));
      const subDomain = this.$route.params.subdomain;

      if (name == "Initial page" || subDomain) {
        this.libraryLoaded = true;
      } else if (name) {
        // these must load first and in this order
        await Promise.all([
          import("./plugins/casl.js"),
          import("./plugins/phonenumber-util.js"),
          import("./plugins/v-clipboard.js"),
          import("./plugins/v-tooltip.js"),
          import("./plugins/vue-circular-count-down-timer.js"),
          import("./plugins/vue-fullscreen.js"),
          import("./plugins/vue-observe-visibility.js"),
          import("./plugins/vue-plyr.js"),
          import("./plugins/vue-scrollto.js"),
          import("./plugins/webrtc-adapter.js"),
          import("./plugins/preventRefresh.js"),
        ]);

        if (oldStore) {
          // Load old store
          const oldState = {
            staticNumbers: oldStore.staticNumbers,
            wWidth: oldStore.wWidth,
            role: oldStore.role,
            initialized: oldStore.initialized,
            isAuthenticating: oldStore.isAuthenticating,
            isConnected: oldStore.isConnected,
            isFetchingAuthenticating: oldStore.isFetchingAuthenticating,
            ccpInstanceId: oldStore.ccpInstanceId,
            isDissmissTestCall: oldStore.isDissmissTestCall,
            isLoading: oldStore.isLoading,
            isModalActive: oldStore.isModalActive,
            isVolumnSilent: oldStore.isVolumnSilent,
            isNitroMode: oldStore.isNitroMode,
            isVolumnControllShow: oldStore.isVolumnControllShow,
            isMicControllShow: oldStore.isMicControllShow,
            isMicSilent: oldStore.isMicSilent,
            isShowTeamStatus: oldStore.isShowTeamStatus,
            isShowAlertsDrawer: oldStore.isShowAlertsDrawer,
            hasAlerts: oldStore.hasAlerts,
            rules: oldStore.rules,
            enableBtnAws: oldStore.enableBtnAws,
            lockDashboardDrag: oldStore.lockDashboardDrag,
            soundVolume: oldStore.soundVolume,
            api: oldStore.api,
            globalCallDuration: oldStore.globalCallDuration,
            dashboardCards: oldStore.dashboardCards,
          };
          this.indexStore.updateState(oldState);
          this.agentStore.updateAgentState(oldStore.agent);
          this.contactStore.updateState(oldStore.contact);
          this.holidayStore.updateState(oldStore.holiday);
          this.permissionStore.updateState(oldStore.securityProfile);
          this.apiStore.updateApiState(oldStore.api);
          this.queueStore.updateState(oldStore.queue);
          this.contactsStore.updateState(oldStore.contacts);
          this.settingStore.updateState(oldStore.settings);
          this.currentUserStore.updateState(oldStore.currentUser);
          this.mediaDeviceStore.updateState(oldStore.mediaDevices);
          this.settingStore.updateAgentState(oldStore.tableConfig);

          await amplify.configure(oldStore.currentUser.profile.Region || "us-east-1");
          try {
            const { idToken } = await Amplify.Auth.currentSession();
            Socket.setToken(idToken);
            Socket.open();
          } catch (err) {
            console.error(err);
          }

          if (Socket || Socket.isReady()) {
            Socket.$on(`${this.agentSummary.Username}-missedVoiceCall`, (data) => {
              this.activityStore.onFetchRecentActivity(this.agentSummary);
            });
          }
        }
        this.libraryLoaded = true;
      }
    }, 1500);
    this.secTimeout = setTimeout(() => {
      if (!this.libraryLoaded) {
        this.$Modal.warning({
          title: this.$t("notifications.loadFailed"),
          content: this.$t("notifications.agentInitialisationDuplicatedQuestion"),
          onOk: async () => {
            try {
              this.$Modal.remove();
              this.$router.go();
            } catch (ex) {
              console.error(ex);
            }
          },
        });
      }
    }, 4000);
  },

  destroyed() {
    localStorage.removeItem("store-initialized");
    clearTimeout(this.initialTimeout);
    clearTimeout(this.secTimeout);
  },
  watch: {
    winWidth() {
      this.checkMobileView();
    },
    newIncomingContact(newContact, oldContact) {
      if (!newContact) {
        this.$Modal.remove();
      }
      if (newContact && oldContact !== newContact && !this.authenticationStore.isEnabledCcpGetter) {
        if (this.autoAcceptCall) {
          this.answerContact(newContact);
          return;
        }

        if (this.isOutboundTask(newContact)) {
          this.answerContact(newContact);
          return;
        }

        const isOnAgentCall = this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected;
        // this.$wait.end("connectingCallToAgent");
        this.$wait.end("rejectingContact");

        if (isOnAgentCall) {
          this.$Modal.confirm({
            title: this.$t("common.warning"),
            content: this.$t("modal.youHaveAnIncomingCallDoYouWantToDropTheCurrentAgentCallAndReceiveTheNewOneInstead"),
            onOk: () => {
              this.handleEndAgentCall();
              this.answerContact(newContact);
            },
            onCancel: () => {
              this.onDissmissIncomingCallUI(newContact);
            },
          });
          return;
        } else {
          this.$wait.end("connectingCallToAgent");
          if (newContact.type === "email") {
            this.playIncomingEmailRingtone();
            if (get(this.api, "getUser.Preferences.SecondaryRingerEmail.isEnabled")) {
              this.playIncomingEmailSecondaryRingtone();
            }
          } else {
            if (!this.contactStore.getMonitorMode) this.playTone(this.alerttone, true);
            console.log("#EM - voice secondary enabled : ", this.isEnabledSecondary);
            if (this.isEnabledSecondary) {
              if (!this.contactStore.getMonitorMode) this.playToneSecondary(this.alerttoneSecondary, true);
            }
          }

          // this.broadcastStatus({ status: { name: "PendingBusy" } });
        }
      }
    },
    agentStatus: {
      immediate: true,
      handler(agentStatus, oldStatus) {
        if (agentStatus) {
          if (agentStatus.name === "MissedCallAgent" || agentStatus.name === "MissedCallCustomer") {
            // TODO make a dummy data instead of requesting
            this.onDismissIncomingCall("MISSED");
            this.showIncomingModal = false;
          }
          if (agentStatus.name === "FailedConnectCustomer") {
            this.$wait.end("connectingCallToAgent");
            this.onDismissIncomingCall("MISSED");
            return (this.showIncomingModal = false);
          }
        }
      },
    },
    language(newLanguage, oldLanguage) {
      if (newLanguage && newLanguage !== oldLanguage) {
        this.$i18n.locale = newLanguage;
      }
    },
    softPhone() {
      const showNativeCcp = window.localStorage.getItem("showNativeCcp") === "true";
      if (this.softPhone === "ccpclient" && !showNativeCcp) {
        window.localStorage.setItem("showNativeCcp", true);
        window.location.href = "/";
      }
      if (this.softPhone === "dextr" && showNativeCcp) {
        window.localStorage.setItem("showNativeCcp", false);
        window.location.href = "/";
      }
    },
    inboundAttributesActiveVoiceContact: {
      handler(newActiveVoiceContact) {
        if (newActiveVoiceContact) {
          const customAttributesActiveVoiceContact = this.contactStore.getInboundAttributesActiveVoiceContact;
          this.iframeIntegrationStore.getUrlsIntegrationFormated({
            customAttributesActiveVoiceContact,
          });
        }
      },
    },
    outboundAttributesActiveVoiceContact: {
      handler(newValue, oldValue) {
        if (!isEmpty(newValue) && isEmpty(oldValue)) {
          const customAttributesActiveVoiceContact = newValue;
          this.iframeIntegrationStore.getUrlsIntegrationFormated({
            customAttributesActiveVoiceContact,
          });
        }
      },
    },
  },

  methods: {
    keyListener(event) {},

    isOutboundTask(contact) {
      return (
        (contact.type === "sms" || contact.type === "email") &&
        !contact.isInbound &&
        contact.data?.attributes?.Direction?.value === "Outbound"
      );
    },

    checkMobileView() {
      const body = document.getElementsByTagName("body")[0];
      if (this.mobileView) {
        body.classList.add("mobile-body");
      } else {
        body.classList.remove("mobile-body");
      }
    },
    dummyCallFormProps() {
      let randomNumber = this.dummyPhoneNumbers[this.generateRandomNumber(0, this.dummyPhoneNumbers.length - 1)];
      let randomQueue = this.dummyQueue[this.generateRandomNumber(0, this.dummyQueue.length - 1)];
      return {
        phoneNumber: randomNumber,
        queue: randomQueue,
      };
    },
    generateRandomNumber(min = 0, max = 1) {
      return Math.round(Math.random() * (max - min) + min);
    },
    async onDismissIncomingCall(value) {
      this.$wait.start("rejectingContact"); // This is ended in a watcher
      if (this.newIncomingContact && this.newIncomingContact.type === "chat") {
        return this.declineIncomingChat();
      }
      if (this.newIncomingContact) {
        let contact = { ...this.newIncomingContact };

        const { EMAIL, CHAT, SMS, TASK } = CHANNELS;
        const { MISSED, REJECTED } = INITIATION_METHODS;
        if (contact.type === EMAIL || contact.type === CHAT || contact.type === SMS || contact.type === TASK) {
          if (value === MISSED || value === REJECTED) {
            contact.summary.InitiationTimestamp = getTimeObject().offsetToISOString();
            contact.DisconnectTimestamp = contact.summary.InitiationTimestamp;
            contact["InitiationMethod"] = value;
          }
          let activityService = new ActivityService();
          await activityService.postActivity(contact);
          await this.activityStore.onFetchRecentActivity(this.agentSummary);
        }

        this.newIncomingContact.reject();
        this.contactStore.clearIncomingContact();
      }
      this.teamStore.stopIncomingRingtone(null);
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    handleMissedChat() {
      this.contactStore.clearIncomingContact();
      this.teamStore.stopIncomingRingtone(null);
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    declineIncomingChat() {
      // This is ended in a watcher
      this.newIncomingContact.reject();
      this.contactStore.clearIncomingContact();
      this.teamStore.stopIncomingRingtone(null);
      if (ding) ding.pause();
      if (dingSecondary) dingSecondary.pause();
      this.stopRinger();
      this.$wait.end("rejectingContact");
    },
    stopRinger() {
      if (ding) {
        ding.pause();
        ding = null;
      }
      if (dingSecondary) {
        dingSecondary.pause();
        dingSecondary = null;
      }
      if (this.dingPrimaryEmail) {
        this.dingPrimaryEmail.pause();
        this.dingPrimaryEmail = null;
      }
      if (this.dingSecondaryEmail) {
        this.dingSecondaryEmail.pause();
        this.dingSecondaryEmail = null;
      }
      this.hidStore.sendCommandWithArguments({ cmd: "ringCmd", value: false });
    },
    async answerContact(contact) {
      try {
        try {
          this.logStore.addItem({
            component: "DEXTR",
            level: "DEBUG",
            text: `Agent clicked accept contact (${contact?.contactId})`,
          });

          this.hidStore.sendCommandWithArguments({ cmd: "callCmd", value: true });

          const { summary, taskSource, attributes } = contact;
          if (
            summary.type === ContactFilter.TASK &&
            taskSource === oneClickSetup.dextr &&
            attributes?.Channel !== ContactFilter.CUSTOMTASK &&
            summary?.ChannelName !== oneClickSetup.name
          ) {
            await contact.listMessages();
          }
        } catch (error) {
          console.error(error);
        }

        this.$wait.start("connectingCallToAgent"); // This is ended in a watcher

        this.stopRinger();

        await contact.accept().catch((err) => {
          throw err;
        });
        this.contactStore.clearIncomingContact();

        this.$wait.end("connectingCallToAgent");

        try {
          let tabname;
          switch (contact.type) {
            case "chat":
              tabname = "chat";
              break;
            case "voice":
              tabname = "phone";

              let initialConnection = contact?.root?.getInitialConnection();
              let initialConnectionState = initialConnection?.getStatus();

              if (!initialConnection || initialConnectionState?.type?.toLowerCase() == "disconnected") {
                this.logStore.addItem({
                  component: "DEXTR",
                  level: "DEBUG",
                  text: `Caller hung up (${contact?.contactId}) before agent could accept`,
                });
              }

              break;
            case "sms":
              tabname = "task";
              break;
            case "task":
              tabname = "task";
              break;
            case "email":
              tabname = "task";
              break;
            case "queue_callback":
              tabname = "phone";
              break;
            default:
              tabname = contact.type;
          }

          let id = contact?.attributes?.ConversationId?.value || contact.contactId;

          if (!this.$route.query.set) {
            this.$router.push(
              `/engage?tab=${tabname}${
                contact.type === "voice" || contact.type === "queue_callback" || contact.type === "chat" ? "" : "&set=" + id
              }`
            );
          }
        } catch (error) {
          console.error(error);
        }
        if (contact.type === CHANNELS.TASK || contact.type === CHANNELS.SMS) {
          let activityService = new ActivityService();
          await activityService.postActivity(contact);
        }
        await this.activityStore.onFetchRecentActivity(this.agentSummary);
      } catch (err) {
        console.error(err);

        this.stopRinger();
        this.contactStore.clearIncomingContact();

        this.$wait.end("connectingCallToAgent");

        this.logStore.addItem({
          component: "DEXTR",
          level: "ERROR",
          text: `Error accepting contact (${contact?.contactId})`,
        });
        this.logStore.addItem({
          component: "DEXTR",
          level: "ERROR",
          text: err?.name + ": " + err?.message,
        });
      }
    },
    handleEndAgentCall() {
      // if this is the caller
      if (this.teamStore.voiceCallData && this.teamStore.voiceCallData.initiator === this.currentUser?.Username) {
        // if the WebRTC connection is not established yet
        if (this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected) {
          // send an event to let the callee knows so that they can end the waiting
          this.teamStore.endVoiceCall({
            InstanceId: this.agentSummary.InstanceId,
          });
        }

        // reset the current WebRTC setup and destroy the connection
        this.teamStore.destroyVoiceCall();
        this.chatStore.setDrawerMutation(false);
        this.chatStore.setUserSelectedMutation(null);
      } else if (this.teamStore.voiceCallData && this.teamStore.voiceCallData.callee === this.currentUser?.Username) {
        // this is the callee
        if (this.callStatus === PeerCallStatus.Connecting || this.callStatus === PeerCallStatus.Connected) {
          // send an event to let the caller knows so that they can end the waiting
          this.teamStore.endVoiceCallFromCallee({ rejected: false });
          this.teamStore.stopIncomingRingtone();
        }
        this.teamStore.destroyPeer();
        // reset the current WebRTC setup and destroy the connection
        this.chatStore.setDrawerMutation(false);
        this.chatStore.setUserSelectedMutation(null);
      }
      // else {
      // }
    },
    async onDissmissIncomingCallUI(contact) {
      this.contactStore.destroyContact(contact);
      this.onDismissIncomingCall("REJECTED");
    },
    playTone(v, isRepeat) {
      for (var el in this.tones) {
        if (Object.prototype.hasOwnProperty.call(this.tones, el)) {
          if (el == v) {
            ding = new Audio(this.tones[el]);
            if (typeof ding.setSinkId === "function") {
              ding.setSinkId(this.deviceId);
            }
            if (isRepeat) ding.loop = true;
            ding.volume = this.primaryToneVolume;
            ding.play();
          }
        }
      }
    },
    playToneSecondary(v, isRepeat) {
      try {
        dingSecondary = new Audio(this.tonesSecondaryMain[v]);
        if (typeof dingSecondary.setSinkId === "function") {
          dingSecondary.setSinkId(this.deviceIdSecondary);
        }
        dingSecondary.volume = this.secondaryToneVolume;
        dingSecondary.play();
        dingSecondary.loop = true;
      } catch (err) {
        console.error("error playing secondary tone: ", err);
      }
    },

    playIncomingEmailRingtone(v, isRepeat) {
      let deviceId = get(this.api, "getUser.Preferences.PrimaryRingerEmail.DeviceId");

      let find = this.mediaDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        deviceId = find.deviceId;
      } else {
        deviceId = "default";
      }

      const ringToneName = get(this.api, "getUser.Preferences.PrimaryRingerEmail.Sound") || "default";

      this.dingPrimaryEmail = null;
      for (let el in this.tones) {
        if (Object.prototype.hasOwnProperty.call(this.tones, el)) {
          if (el == ringToneName) {
            this.dingPrimaryEmail = new Audio(this.tones[el]);
            if (typeof this.dingPrimaryEmail.setSinkId === "function") {
              this.dingPrimaryEmail.setSinkId(deviceId);
            }
            this.dingPrimaryEmail.loop = true;
            this.dingPrimaryEmail.volume = (get(this.api, "getUser.Preferences.PrimaryRingerEmail.Volume") || 50) / 100;
            this.dingPrimaryEmail.play();
          }
        }
      }
    },

    playIncomingEmailSecondaryRingtone(v, isRepeat) {
      //stop current playing ringtone, before starting it again

      let secondaryDeviceID = get(this.api, "getUser.Preferences.SecondaryRingerEmail.DeviceId") || "default";

      let find = this.mediaDevices.find((i) => secondaryDeviceID === i.deviceId);
      if (find) {
        secondaryDeviceID = find.deviceId;
      } else {
        secondaryDeviceID = "default";
      }

      const ringToneNameSecondary = get(this.api, "getUser.Preferences.SecondaryRingerEmail.Sound") || "default";

      const ringToneSecondary = this.tonesSecondaryMain[ringToneNameSecondary];
      this.dingSecondaryEmail = null;
      this.dingSecondaryEmail = new Audio(ringToneSecondary);
      if (typeof this.dingSecondaryEmail.setSinkId === "function") {
        this.dingSecondaryEmail.setSinkId(secondaryDeviceID);
      }
      this.dingSecondaryEmail.volume = (get(this.api, "getUser.Preferences.SecondaryRingerEmail.Volume") || 50) / 100;
      this.dingSecondaryEmail.loop = true;
      this.dingSecondaryEmail.play();
    },

    onDismissIncomingSMS() {
      this.phoneStore.newSMSContactReceivedMutation(null);
      this.phoneStore.setShowSMSContact(false);
    },
    onAcceptNewSMS() {
      let InstanceId = this.newSMSContactReceived.InstanceId;
      let ContactId = this.newSMSContactReceived.id;
      let Accept = true;
      let Username = this.agentSummary.Username;

      this.phoneStore
        .acceptSmsConversation({
          InstanceId,
          ContactId,
          Accept,
          Username,
        })
        .then((res) => this.onSuccessfulAcceptNewSMS());

      this.phoneStore.stopIncomingSmsRingtone();

      const newActivity = {
        Channel: "sms",
        InitiationMethod: "INBOUND",
        CustomerEndpoint: this.newSMSContactReceived.From,
        InitiationTimestamp: getTimeObject().getOffsetTime(),
        Queue: this.newSMSContactReceived.MatchedQueueName,
        id: this.newSMSContactReceived.id,
      };
      this.activityStore.updateOrNewListRecentActivity(newActivity);
    },
    onSuccessfulAcceptNewSMS() {
      this.phoneStore.onNewContactReceived(this.newSMSContactReceived);
      this.$router.push("/engage?tab=task&set=" + this.newSMSContactReceived.id);
      this.phoneStore.setSelectedThread(this.newSMSContactReceived.id, {
        root: 1,
      });
      this.phoneStore.onNewSmsMessage({
        InstanceId: this.newSMSContactReceived.InstanceId,
        ContactId: this.newSMSContactReceived.id,
      });
      this.phoneStore.newSMSContactReceivedMutation(null);
      this.phoneStore.setShowSMSContact(false);
    },
    onDeclineNewSMS() {
      let id = clone(this.newSMSContactReceived.id);
      let InstanceId = this.newSMSContactReceived.InstanceId;
      let ContactId = this.newSMSContactReceived.id;
      let Accept = false;
      let Username = this.agentSummary.Username;

      this.phoneStore
        .acceptSmsConversation({
          InstanceId,
          ContactId,
          Accept,
          Username,
        })
        .finally(() => {
          this.phoneStore.removeConvo({ id });
        });

      this.phoneStore.stopIncomingSmsRingtone();
      this.onDismissIncomingSMS();
    },
    subscribeIncoming() {
      let InstanceId = this.agentSummary.InstanceId;
      let Username = this.agentSummary.Username;

      this.phoneStore.onNewSmsContact({ InstanceId, Username });
      this.channelStore.onNewEmailContact({ InstanceId, Username });

      this.phoneStore.subscribeToAllOngoingSmsContacts();
      this.subscribeToAllOngoingEmailContacts();
    },
    unsubscribeIncoming() {
      this.phoneStore.endSubscription({ name: "onNewSmsContact" });
      this.channelStore.endEmailSubscription({ name: "onNewEmailContact" });
      this.channelStore.unsubscribeToAllOnNewEmailMessageSubscription();
      this.phoneStore.unsubscribeToAllOnNewSmsMessageSubscription();
    },
  },
};
</script>

<style lang="scss">
@import "~vue-tel-input/dist/vue-tel-input.css";
@import "./assets/scss/master";

.ivu-table-cell {
  word-break: break-word !important;
}

.dark-app {
  background: #36393f !important;
  .m-page-wrapper {
    background: #36393f !important;
    height: 100vh;
  }
  *::-webkit-scrollbar {
    width: 10px !important;
  }
  *::-webkit-scrollbar-track {
    background: #2c2f33;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #202225;
    border-radius: 10px;
  }

  table tr {
    th {
      background: #23272a !important;
      color: white;
      border-color: #23272a;
    }
    td {
      background: #2c2f33 !important;
      color: white;
      border-color: #23272a;
      strong {
        color: white;
      }
    }
  }
  .ivu-table-wrapper {
    border-color: #23272a;
    .ivu-table {
      background: #36393f !important;
    }
  }

  .ivu-table-fixed-right::before,
  .ivu-table-fixed::before,
  .ivu-table:after,
  .ivu-table:before {
    background: #23272a !important;
  }
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  100% {
    opacity: 1;
  }
}
</style>
